<!--新建活动-->
<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;width:calc(100% - 32px);min-width:max-content">
                    <a-card title="活动描述" style="width:100%;min-height:100%;" :bordered="false">
                        <a-form-model layout="horizontal" style="width:800px;"
                                      ref="ruleForm"
                                      :rules="rules"
                                      :model="Activity"
                                      :label-col="formItemLayout.labelCol"
                                      :wrapper-col="formItemLayout.wrapperCol">
                            <a-form-model-item label="活动名称" prop="Name">
                                <a-input placeholder="请输入名称" v-model="Activity.Name" :disabled="Disable"></a-input>
                            </a-form-model-item>
                            <a-form-model-item label="开始时间"
                                               required
                                               prop="StartTime">
                                <a-date-picker v-model="Activity.StartTime"
                                               show-time
                                               type="date"
                                               :disabled-date="disabledStartDate"
                                               format="YYYY-MM-DD HH:mm:ss"
                                               placeholder="选择开始时间"
                                               style="width:100%"
                                               :disabled="Disable"
                                               @openChange="handleStartOpenChange" />
                            </a-form-model-item>
                            <a-form-model-item label="结束时间"
                                               required
                                               prop="EndTime">
                                <a-date-picker show-time
                                               style="width:100%"
                                               type="date"
                                               :disabled-date="disabledEndDate"
                                               format="YYYY-MM-DD HH:mm:ss"
                                               placeholder="选择结束时间"
                                               :open="endOpen"
                                               :disabled="Disable"
                                               @openChange="handleEndOpenChange"
                                               v-model="Activity.EndTime" />
                            </a-form-model-item>

                            <!--软文 店铺 H5链接-->
                            <a-form-model-item label="选择文章" v-if="Activity.SceneType==2">
                                <a-input placeholder="点击选择文章" disabled v-model="tempNewsTitle" />
                                <a-button type="primary" value="点击选择" size="small" @click="showNews" v-if="!Disable">点击选择</a-button>
                            </a-form-model-item>
                            <a-form-model-item label="H5链接" v-if="Activity.SceneType==0" prop="SceneURL">
                                <div style="margin-bottom: 16px" v-if="Activity.ID==0">
                                    <a-input v-model="Activity.SceneURL" :disabled="Disable" placehoder="请输入H5链接">
                                        <!--<a-select slot="addonBefore" v-model="prefix" style="width: 90px">
                                            <a-select-option value="http://">
                                                http://
                                            </a-select-option>
                                            <a-select-option value="https://">
                                                https://
                                            </a-select-option>
                                        </a-select>-->
                                    </a-input>
                                </div>
                                <div style="margin-bottom: 16px" v-if="Activity.ID!==0">
                                    <a-input v-model="Activity.SceneURL" placeholder="点击选择文章" :disabled="Disable"></a-input>
                                </div>
                            </a-form-model-item>


                            <a-form-model-item label="区域限制">
                                <a-switch v-model="GPSLimit" @click="postionChange" :disabled="Disable">
                                    <a-icon slot="checkedChildren" type="check" />
                                    <a-icon slot="unCheckedChildren" type="close" />
                                </a-switch>
                                <a-button type="primary" value="点击选择" @click="map_display" size="small" style="margin-left:12px;" v-if="!Disable">点击选择</a-button>
                            </a-form-model-item>
                            <a-form-model-item label="地址" v-show="GPSLimit">
                                <a-input placeholder="地址" disabled v-model="mapModel.tempaddress" />
                            </a-form-model-item>
                            <a-card title="活动配置" style="width:100%;" :bordered="false">
                                <a-form-model-item label="阅读到底部" v-if="Activity.SceneType!=0">
                                    <a-switch v-model="ReadBottom" @click="ReadBottomChange" :disabled="Disable">
                                        <a-icon slot="checkedChildren" type="check" />
                                        <a-icon slot="unCheckedChildren" type="close" />
                                    </a-switch>
                                </a-form-model-item>
                                <a-form-model-item label="浏览时长(秒)" prop="ReadSecond">
                                    <a-input-number :min="1" placeholder="" style="width:70px" type="number" v-model="Activity.ReadSecond" :disabled="Disable" />
                                </a-form-model-item>
                            </a-card>
                            <a-card title="活动金额(元)" style="width:100%;" :bordered="false">
                                <a-form-model-item label="活动预算" prop="BudgetMoney">
                                    <a-input-number :min="0.3" placeholder="" style="width:70px" type="number " :step="1" v-model="Activity.BudgetMoney" :disabled="Disable" />
                                </a-form-model-item>
                                <a-form-model-item label="阅读金额" prop="ReadMoney">
                                    <a-input-number :min="0.3" placeholder="" style="width:70px" type="number" :step="0.1" v-model="Activity.ReadMoney" :disabled="Disable" />
                                </a-form-model-item>
                                <a-form-model-item label="分享阅读金额" prop="ShareMoney">
                                    <a-input-number :min="0" placeholder="" style="width:70px" type="number" :step="0.1" v-model="Activity.ShareMoney" :disabled="Disable" />
                                </a-form-model-item>
                                <a-form-model-item label="个人最大领取金额" prop="PersonalMoneyMax">
                                    <a-input-number :min="0" placeholder="" style="width:70px" type="number" :step="0.1" v-model="Activity.PersonalMoneyMax" :disabled="Disable" />
                                </a-form-model-item>
                            </a-card>
                            <a-form-model-item label class="textal_c" v-if="!Disable">
                                <div v-if="this.$route.query.rowType !='select'">
                                    <a-button type="primary" @click="save('ruleForm')">保存</a-button>
                                </div>
                            </a-form-model-item>
                            <a-form-model-item label class="textal_c" v-if="Disable">

                                <a-button type="primary" @click="back">返回</a-button>

                            </a-form-model-item>
                        </a-form-model>
                    </a-card>
                </div>
            </a-layout-content>
        </a-layout>
        <!--地图-->
        <a-modal v-model="mapModelDisplay"
                 title="选择活动区域"
                 centered
                 okText="确定"
                 cancelText="取消"
                 width="900px"
                 height="700px"
                 :maskClosable="false"
                 @ok="confirmAddress">
            <div style="width: 100%; height:auto;">
                <div id="map_title">
                    请以当前
                    <a href="javascript:void(0)" onclick="map_location();" style="color:red;font-weight:400;font-size:20px;">您的位置</a>为中心，选择本次活动范围。
                    <br>
                    <span>备注：可拖动红色中心标示，改变您当前位置</span>
                </div>
                <div id="map_sel">
                    <a-select :default-value="circlePath.radius" style="width: 120px" @change="radiusChange">
                        <a-select-option value="50">50米</a-select-option>
                        <a-select-option value="100">100米</a-select-option>
                        <a-select-option value="500">500米</a-select-option>
                        <a-select-option value="1000">1公里</a-select-option>
                        <a-select-option value="5000">5公里</a-select-option>
                        <a-select-option value="10000">10公里</a-select-option>
                        <a-select-option value="15000">15公里</a-select-option>
                        <a-select-option value="20000">20公里</a-select-option>
                        <a-select-option value="30000">30公里</a-select-option>
                        <a-select-option value="50000">50公里</a-select-option>
                        <a-select-option value="100000">100公里</a-select-option>
                    </a-select>
                </div>
                <baidu-map class="bm-view" ak="E8f3c743837925ce120eb93417f478db" :center="mapModel.center" :zoom="mapModel.zoom" :scroll-wheel-zoom="true" @ready="mapReady">
                    <bm-marker :position="mapModel.center" :dragging="true" @dragend="dragend">
                    </bm-marker>
                    <bm-circle :center="circlePath.center" :radius="circlePath.radius" :editing="circlePath.editing" stroke-color="blue" :stroke-opacity="0.5" :stroke-weight="2"></bm-circle>
                </baidu-map>
            </div>
        </a-modal>


        <!--软文-->
        <a-modal v-model="News.IsDisplay"
                 title="软文选择"
                 centered
                 okText="确定"
                 cancelText="取消"
                 width="900px"
                 height="700px"
                 @ok="onNewsOk">
            <div style="width: 100%; height:auto;">

                <ul class="form-search">
                    <li style="margin:0">
                        <label>标题</label>
                        <a-input placeholder="输入标题" v-model="News.Title"></a-input>
                    </li>
                    <!--<li>
                        <label>资讯内容</label>
                        <a-input placeholder="输入资讯内容" v-model="Activity.Name"></a-input>
                    </li>-->
                </ul>

                <a-button type="primary" value="查询" @click="getNewsList" size="small" style="margin-left:12px;height:28px;padding-left:12px;padding-right:12px;">查询</a-button>

                <div id="boxdiv" style="padding-top:16px;">
                    <a-radio-group :default-value="News.ChooseID" @change="onNewsChange">
                        <table id="example2" class="table  table-hover">
                            <thead>
                                <tr>
                                    <th width="40"></th>
                                    <th width="100">封面图</th>
                                    <th>标题</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr v-for="item in News.NewsList" :key="item.ID">
                                    <td>
                                        <a-radio :value="item.ID"></a-radio>
                                    </td>
                                    <td align="center">
                                        <img :src="item.TitleImg" height="80" />
                                    </td>
                                    <td title="NewsTitle">{{item.NewsTitle}}</td>
                                    <!--<td style="display:none" title="ID">ID</td>-->
                                </tr>

                            </tbody>
                        </table>
                    </a-radio-group>
                </div>
            </div>
        </a-modal>
        <!--推广软文   当前时间段存在活动文章-->
        <a-modal v-model="exsitArticleModal" title="消息提示">

            <div>
                <p>当前活动时间段已经存在同类型活动，请修改活动时间{{exsitMessage}}</p>
                <div v-for="item in ExsitArticleList" :key="item.ID">
                    活动标题：<a-button type="link" size="small" @click="ActivityDetail(item.ID,2,false)">{{item.Name}}</a-button>
                </div>
            </div>
            <div slot="footer"></div>
        </a-modal>
    </div>
</template>
<script type="text/javascript">
    import util from "@/Plugin/util.js";
    import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
    import http from "@/Plugin/Http.js";
    //import Rich from "@/components/RichEditor.vue";
    import { BmCircle } from 'vue-baidu-map'
    import { BmMarker } from 'vue-baidu-map'


    export default {
        data() {
            return {

                News: {
                    ChooseID: -1,
                    IsDisplay: false,
                    NewsList: [],
                    Title: ""
                },
                tempNewsTitle: "",//文章标题
                templates: [],//招生店铺模板
                Activity: {
                    ID: this.$route.query.ID,
                    SceneType: this.$route.query.SceneType,//活动类型 暂无
                    Name: "",//名称
                    SceneIdentify: "",//身份标识
                    StartTime: "",//开始时间
                    EndTime: "",//结束时间
                    SceneURL: "",//H5链接
                    Lng: 0.00,//
                    Lat: 0.00,//
                    RadiusLength: 50,//半径长度
                    GPSLimit: 0,//地理位置限制
                    ReadSecond: 1,//阅读时间
                    ReadBottom: 0,//阅读到底部
                    BudgetMoney: 0.3,//预算金额
                    PersonalMoneyMax: 0,//个人最大金额
                    ReadMoney: 0.3,//阅读金额
                    ShareMoney: 0,//分享金额
                    CenterPointAddress: ""
                },
                exsitMessage: "",//已经存在同类型活动的消息提示
                ExsitArticleList: [],//当前时间段已经存在同类型额推广软文文章
                exsitArticleModal: false, //判断时间段内存在文章模态框
                GPSLimit: false,
                ReadBottom: false, //阅读到底部
                Disable: false, //查看
                isChangePosition: false,//是否已经移动了点，没有移动则使用默认位置
                circle: null,
                mapModelDisplay: false, //地图模态框
                isloadDefaultInfo: false,//是否已经加载默认地理位置信息
                mapModel: {
                    center: { lng: 114.383176, lat: 36.062233 },
                    zoom: 13,
                    tempaddress: ""
                },
                prefix: "",//h5链接url
                circlePath: {
                    center: {
                        lng: 114.383176,
                        lat: 36.062233
                    },
                    radius: 50,
                    editing: false
                },
                readBottomModel: { //阅读到底部
                    ReadPriceIsOpen: true,
                    ReadPriceSpan: "开启",
                    CheckClass: "is-checked"
                },
                endOpen: false,
                rules: {
                    Name: [{ message: "请输入活动名称", required: true, trigger: "blur" }],
                    SceneURL: [{ message: "请输入H5链接", required: true, trigger: "blur" }],
                    ReadSecond: [{ message: "请输入浏览时长", type: "integer", required: true, trigger: "change" }],
                    BudgetMoney: [{ message: "请输入活动预算", required: true, trigger: "blur" }],
                    PersonalMoneyMax: [{ message: "请输入个人领取最大金额", required: true, trigger: "blur" }],
                    ReadMoney: [{ message: "请输入阅读金额", required: true, trigger: "blur" }],
                    ShareMoney: [{ message: "请输入分享阅读金额", required: true, trigger: "blur" }],
                    StartTime: [{ message: "请输入开始时间", required: true, trigger: "change" }],
                    EndTime: [{ message: "请输入结束时间", required: true, trigger: "change" }],

                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 5 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 19 },
                    },
                },
            };
        },
        methods: {

            //推广软文详情活动
            ActivityDetail(ID, SceneType, Disable) {
                this.exsitArticleModal = false
                this.Activity.ID = ID;
                this.Disable = Disable
                this.Activity.SceneType = SceneType
                this.getData();
            },
            //获取商户默认位置信息
            GetDefaultInfo() {
                var self = this
                var op = {
                    url: "/MarketingModule/Activity/GetCustomPosition",
                    data: {
                    },
                    OnSuccess: function (res) {
                        if (res.data != null) {
                            self.GPSLimit = res.data.GPSLimit == 1 ? true : false;
                            self.Activity.Lat = res.data.Lat;
                            self.Activity.Lng = res.data.Lng;
                            self.Activity.RadiusLength = res.data.RadiusLength;
                            self.mapModel = {
                                center: { lng: res.data.Lng, lat: res.data.Lat },
                                zoom: 13,
                                tempaddress: "以【" + res.data.CenterPointAddress + "】为中心方圆【" + res.data.RadiusLength + "】米"
                            }
                            self.circlePath = {
                                center: {
                                    lng: res.data.Lng,
                                    lat: res.data.Lat
                                },
                                radius: res.data.RadiusLength,
                                editing: false
                            }
                            self.Activity.CenterPointAddress = res.data.CenterPointAddress
                        }
                    }
                };
                http.Post(op);
            },
            //切换阅读到底部限制
            ReadBottomChange(val) {
                this.ReadBottom = val;
                console.log(this.Activity)

            },
            getImagePath: function (data) {
                if (data != null) {
                    return data.ImagePath;
                } else {
                    return "";
                }
            },

            //显示软文
            showNews: function () {
                var self = this;
                self.News.IsDisplay = true;
                console.log(self.News.ChooseID)
            },
            //返回活动列表页
            back() {
                this.$router.push({
                    name: "Activity_List", query: {
                    }
                })

            },
            onNewsChange(e) {
                var self = this;
                self.News.ChooseID = e.target.value;
                console.log(self.News.ChooseID)
            },
            onNewsOk: function () {
                var self = this;
                var result = self.News.NewsList.filter(function (item) {
                    return item.ID == self.News.ChooseID;
                });
                //查找选择的软文明细
                self.tempNewsTitle = result[0].NewsTitle;
                self.Activity.SceneIdentify = result[0].ID;
                self.News.IsDisplay = false;

            },

            deleteCurrentRow: function (obj) {
                var self = this;
                var index = self.Activity.Rewards.findIndex(item => {
                    if (item.Rank == obj.Rank) {
                        return true
                    }
                })
                self.Activity.Rewards.splice(index, 1);
            },
            //打开区域限制，选择位置
            map_display: function () {
                var self = this;
                self.mapModelDisplay = true;
            },
            confirmAddress: function () {
                var self = this;
                //console.log(self.isChangePosition)
                //if (!self.isChangePosition) {

                //}
                var radius = self.Activity.RadiusLength
                if (radius >= 1000) {
                    radius = radius / 1000;
                    self.mapModel.tempaddress = "以【" + self.Activity.CenterPointAddress + "】为中心方圆【" + radius + "】公里";
                } else {
                    self.mapModel.tempaddress = "以【" + self.Activity.CenterPointAddress + "】为中心方圆【" + radius + "】米";
                }
                self.mapModelDisplay = false;
            },
            //地图插件加载
            mapReady: function ({ BMap, map }) {
                console.log(map);
                var self = this;
                self.gec = new BMap.Geocoder();
                self.Activity.Lng = self.mapModel.center.lng;
                self.Activity.Lat = self.mapModel.center.lat;
            },
            //地图图标拖动
            dragend: function (type) {
                var self = this;
                self.isChangePosition = true;
                self.circlePath.center = type.point
                self.Activity.Lng = type.point.lng;
                self.Activity.Lat = type.point.lat;
                self.gec.getLocation(type.point, function (rs) {
                    var addComp = rs.addressComponents;
                    var trainPlace = addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber;
                    var content = trainPlace;// "<br/><br/>经度：" + myPoint.lng + "<br/>纬度：" + myPoint.lat;
                    var radius = self.Activity.RadiusLength
                    console.log(radius)
                    if (radius > 999) {
                        console.log("11")

                        radius = radius / 1000;
                        self.mapModel.tempaddress = "以【" + content + "】为中心方圆【" + radius + "】公里";
                    } else {
                        console.log("22")
                        self.mapModel.tempaddress = "以【" + content + "】为中心方圆【" + radius + "】米";
                    }
                    console.log("33")
                    self.Activity.CenterPointAddress = content
                });
            },
            //地图位置限制区域
            radiusChange: function (value) {
                var self = this;
                self.circlePath.radius = value;
                self.Activity.RadiusLength = value;
                console.log(value);
            },
            postionChange: function (val) {
                this.GPSLimit = val;
                if (this.GPSLimit && !this.isloadDefaultInfo) {
                    this.GetDefaultInfo();
                    this.isloadDefaultInfo = true

                }
            },

            getNewsList: function () {
                var self = this
                var op = {
                    url: "/MarketingModule/Material/NewsList",
                    data: {
                        NewsTitle: self.News.Title
                    },
                    OnSuccess: function (data) {
                        if (data.data.Data.length > 0) {
                            self.News.NewsList = data.data.Data;

                            if (self.News.ChooseID == -1) {
                                self.News.ChooseID = self.News.NewsList[0].ID;
                            }
                            self.News.IsDisplay = false;
                        }
                    }
                };
                http.Post(op);
            },

            SharePriceChange(value) {
                this.Activity.SharePrice = value;
            },

            getData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Activity/GetActivityInfoByID",
                    data: {
                        ActivityID: self.Activity.ID,
                    },
                    OnSuccess: function (res) {
                        var model = res.data
                        if (model != null) {
                            self.Activity.ID = model.ID;
                            self.Activity.SceneType = model.SceneType;
                            self.Activity.Name = model.Name;//名称
                            self.Activity.SceneIdentify = model.SceneIdentify;//身份标识
                            //开始时间
                            self.Activity.StartTime = util.TimeStamptoDateTime(res.data.StartTime, 'yyyy-MM-dd hh:mm:ss')
                            self.Activity.EndTime = util.TimeStamptoDateTime(res.data.EndTime, 'yyyy-MM-dd hh:mm:ss');
                            self.Activity.SceneURL = model.SceneURL;//H5链接
                            self.Activity.Lng = model.Lng;//
                            self.Activity.Lat = model.Lat;//
                            self.Activity.RadiusLength = model.RadiusLength;//半径长度
                            self.Activity.GPSLimit = model.GPSLimit;//地理位置限制
                            self.GPSLimit = model.GPSLimit == 1 ? true : false;
                            self.ReadBottom = model.ReadBottom == 1 ? true : false
                            self.Activity.ReadSecond = model.ReadSecond;//阅读时间
                            self.Activity.ReadBottom = model.ReadBottom;//阅读到底部
                            self.Activity.BudgetMoney = model.BudgetMoney;//预算金额
                            self.Activity.PersonalMoneyMax = model.PersonalMoneyMax;//个人最大金额
                            self.Activity.ReadMoney = model.ReadMoney;//阅读金额
                            self.Activity.ShareMoney = model.ShareMoney;//分享金额
                            self.Activity.CenterPointAddress = model.CenterPointAddress;
                            self.tempNewsTitle = model.NewsTitle;//软文标题
                            self.News.ChooseID = model.NewsID == null ? -1 : model.NewsID;// 软文id
                            var radiusUnit = model.RadiusLength < 1000 ? "米" : "公里"
                            var radiusLength = model.RadiusLength < 1000 ? model.RadiusLength : model.RadiusLength / 1000;
                            self.mapModel = {
                                center: { lng: model.Lng, lat: model.Lat },
                                zoom: 13,
                                tempaddress: "以【" + model.CenterPointAddress + "】为中心方圆【" + radiusLength + "】" + radiusUnit
                            }
                            self.circlePath = {
                                center: {
                                    lng: model.Lng,
                                    lat: model.Lat
                                },
                                radius: (model.RadiusLength).toString(),
                                editing: false
                            }

                        }
                        console.log(self.News.ChooseID)
                    }

                };
                http.Post(op);
            },
            save: function (formName) {

                this.$refs[formName].validate(valid => {
                    if (valid) {
                        var self = this;
                        if (util.isBlank(self.Activity.EndTime._d)) {
                            if (self.Activity.EndTime < util.formatDateTime(new Date())) {
                                self.$message.error("结束时间小于当前时间");
                                return false;
                            }
                        } else {
                            if (self.Activity.EndTime._d < new Date()) {
                                self.$message.error("结束时间小于当前时间");
                                return false;
                            }
                        }

                        if (util.isBlank(self.tempNewsTitle) && self.Activity.SceneType == 2) {
                            self.$message.error("请选择文章");
                            return false;
                        }
                        self.Activity.GPSLimit = self.GPSLimit == true ? 1 : 0;
                        self.Activity.ReadBottom = self.ReadBottom == true ? 1 : 0;
                        if (self.Activity.ReadSecond==0&& !self.ReadBottom ) {
                             self.$message.error("活动配置条件不满足！请打开【阅读到底部】或设置【浏览时长】");
                            return false;
                        }
                        var op1 = {
                            url: "/MarketingModule/Activity/isActivityTimeExsit",
                            data: {
                                StartTime: self.Activity.StartTime,
                                EndTime: self.Activity.EndTime,
                                SceneIdentity: self.Activity.SceneType == 0 ? self.Activity.SceneURL : self.Activity.SceneIdentify,
                                ID: self.Activity.ID,
                                SceneType: self.Activity.SceneType
                            },
                            OnSuccess: function (res) {
                                console.log(res.data)
                                if (res.data.length > 0) {
                                    self.ExsitArticleList = res.data;
                                    self.exsitArticleModal = true;
                                    if (self.Activity.SceneType == 0) {
                                        self.exsitMessage = "或H5链接";
                                    } else if (self.Activity.SceneType == 2) {
                                        self.exsitMessage = "或软文链接";
                                    } else {
                                         self.exsitMessage =""
                                    }
                                    return false;
                                } else {
                                    self.ExsitArticleList = []
                                }
                            }
                        };
                        http.Post(op1);
                
                        if (self.Activity.BudgetMoney < self.Activity.PersonalMoneyMax) {

                            self.$message.error("个人最大领取金额超过活动预算");
                            return false;
                        }
                        if (self.Activity.BudgetMoney < self.Activity.ReadMoney) {

                            self.$message.error("阅读金额超过活动预算");
                            return false;
                        }
                        if (self.Activity.BudgetMoney < self.Activity.ShareMoney) {

                            self.$message.error("分享阅读金额超过活动预算");
                            return false;
                        }
                        if (self.Activity.BudgetMoney < (self.Activity.ShareMoney + self.Activity.ReadMoney)) {

                            self.$message.error("阅读金额和分享阅读金额的总额超过活动预算");
                            return false;
                        }


                        if (self.Activity.ShareMoney < 0.3 && self.Activity.ShareMoney > 0) {
                            self.$message.error("分享金额不能在0~0.3范围之间");
                            return false;
                        }
                        if (self.Activity.PersonalMoneyMax < 0.3 && self.Activity.PersonalMoneyMax > 0) {
                            self.$message.error("个人可获得最大金额不能在0~0.3范围之间");
                            return false;
                        }

                        if (self.Activity.SceneType == 0 && self.Activity.ID == 0) {
                            self.Activity.SceneURL = self.prefix + self.Activity.SceneURL;
                        }
                        if (!self.GPSLimit) {
                            self.Activity.Lng = 0.0;
                            self.Activity.Lat = 0.0;
                        }
                       
                        setTimeout(function () {
                            if (self.ExsitArticleList.length <= 0) {
                                var op = {
                                    url: "/MarketingModule/Activity/SaveActivityInfo",
                                    data: self.Activity,
                                    OnSuccess: function () {
                                        var display = "新增成功";
                                        if (self.Activity.ID != null && self.Activity.ID != 0) {
                                            display = "编辑成功";
                                        }
                                        self.$message.success(
                                            display,
                                            1.5,
                                            function () {
                                                self.$router.push({
                                                    name: "Activity_List", query: {
                                                    }
                                                })
                                            }
                                        );
                                    }
                                };
                                http.Post(op);
                            }
                        }, 1000)

                    } else {
                        return false;
                    }
                });
            },
            disabledStartDate(startValue) {
                const endValue = this.Activity.EndTime;
                if (!startValue || !endValue) {
                    return false;
                }
                return startValue.valueOf() > endValue.valueOf();
            },
            disabledEndDate(endValue) {
                const startValue = this.Activity.StartTime;
                if (!endValue || !startValue) {
                    return false;
                }
                return startValue.valueOf() >= endValue.valueOf();
            },
            handleStartOpenChange(open) {
                if (!open) {
                    this.endOpen = true;
                }
            },
            handleEndOpenChange(open) {

                this.endOpen = open;
            },
            RankChange(item) {
                console.log(item);
            },
            RankKeyUp(item) {
                console.log(item);
            },
            RewardChange(item) {
                console.log(item);
            },
        },
        components: {
            BaiduMap,
            BmCircle,
            BmMarker,
            //Rich
        },
        mounted() {
            if (!util.isBlank(this.$route.query.Disable)) {
                this.Disable = this.$route.query.Disable == 1 ? true : false;
            }
            if (util.isBlank(this.$route.query.ID)) {
                this.Activity.ID = 0;
                this.GetDefaultInfo();
            } else {
                this.getData();
            }
            var SceneType = this.$route.query.SceneType
            if (!util.isBlank(SceneType)) {
                SceneType = parseInt(SceneType);
                switch (SceneType) {
                    case 2:
                        this.getNewsList();
                        break;
                }
            }

        },
        watch: {
            startValue(val) {
                console.log('startValue', val);
            },
            endValue(val) {
                console.log('endValue', val);
            },
        },
    }
</script>
<style type="text/css">
    .bm-view {
        width: 100%;
        height: 600px;
    }

    #map_title {
        position: absolute;
        z-index: 9999;
        left: 23px;
        right: 23px;
        height: 60px;
        background-color: #09f;
        padding-top: 10px;
        padding-left: 20px;
        color: white;
    }

    #map_sel {
        position: absolute;
        z-index: 9999;
        right: 106px;
        top: 92px;
    }

    #map_btns {
        position: absolute;
        z-index: 9999;
        bottom: 30px;
        width: 100%;
    }

        #map_btns input[type='button'] {
            width: 120px;
            height: 45px;
            margin-top: 20px;
            color: #fff;
            border: none;
            border-radius: 30px;
            opacity: 0.8;
        }

    .map_save {
        background-color: green;
        margin-right: 40px;
    }

    .map_cancel {
        background-color: #f15b5b;
    }

    .solid-btn {
        background: #32a9ff;
        color: #fff !important;
        padding: 4px 18px;
        border: none;
        border-radius: 2px;
        transition: all .15s ease-in-out;
        margin-top: -3px;
    }

    .yj-storecon-template > div {
        height: 100px;
        width: 100px;
        overflow: hidden;
        text-align: center;
        flex-shrink: 0;
       /* filter: grayscale(80%); */
    }
</style>
<style type="text/css">
    .content {
        min-height: 250px;
        padding: 15px;
        margin-right: auto;
        margin-left: auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    .content {
        margin: 15px 20px 15px 15px;
        padding-top: 0;
    }

    .form-search {
        display: block;
        list-style-type: disc;
        padding-left: 0;
    }

    ul, ol {
        margin-top: 0;
        margin-bottom: 10px;
    }

    /*列表CSS*/
    /*.col-xs-12 {
        width: 100%;
    }
    .box {
        border-top: none;
        box-shadow: none;
    }


    .box {
        position: relative;
        border-radius: 3px;
        background: #ffffff;
        border-top: 3px solid #d2d6de;
        margin-bottom: 20px;
        width: 100%;
        box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    }*/
    .box {
        border-top: none;
        box-shadow: none;
    }

    .right-btn {
        margin-top: 18px;
    }

    .line-btn {
        border: 1px solid #32a9ff;
        color: #32a9ff;
        padding: 7px 15px;
        background: #fff;
        transition: all .15s ease-in-out;
    }

    .gray-line-btn {
        border: 1px solid #e5e5e5;
        color: #666;
        padding: 7px 15px;
        background: #fff;
        transition: all .15s ease-in-out;
        margin-left: 20px;
    }

    .gray-line-btn {
        cursor: pointer
    }

    .solid-btn {
        background: #32a9ff;
        color: #fff !important;
        padding: 4px 18px;
        border: none;
        border-radius: 2px;
        transition: all .15s ease-in-out;
        margin-top: -3px;
    }

        .solid-btn:hover {
            background: #1b99f5;
            cursor: pointer;
            color: #fff
        }

    .num {
        font-size: 26px;
        color: #32a9ff;
        padding: 5px;
        font-weight: 500;
    }

    .inners {
        padding: 10px;
        border: 1px solid #f3f4f5
    }

    .search {
        overflow: hidden;
        background: #fbfbfb;
        margin: 10px auto;
        padding: 10px;
    }

    .line-btn:hover {
        background: #32a9ff;
        color: #fff;
        border-radius: 2px;
        transition: all .15s ease-in-out;
        cursor: pointer;
    }

    .form-search {
        display: block;
        list-style-type: disc;
        padding-left: 0;
    }

    /* .single-row {
    } */

    .mtb5 {
        margin: 5px 0
    }

    .mtb10 {
        margin: 10px 0;
    }

    .mtb20 {
        margin: 20px 0;
    }

    .tr-middle td {
        vertical-align: middle;
    }

    body {
        background: #f3f5f5;
        font-family: Arial,Helvetica,sans-serif,"Microsoft YaHei","ËÎÌå";
        font-size: 15px;
    }

    .sys-name {
        color: #00a2e9;
        margin: 0;
        padding: 0;
        font-size: 25px;
        font-weight: 500;
        height: 68px;
        line-height: 68px;
    }

    .right-opt {
        height: 68px;
        line-height: 68px;
        cursor: pointer;
    }

    .top-top {
        margin-bottom: 10px;
        background: #fff;
        border-bottom: solid 1px #E0E5E5;
    }

    .top-center {
        max-width: 1160px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    .content-wrapper {
        background: #fff;
    }

    .content {
        margin: 15px 20px 15px 15px;
        padding-top: 0
    }

    .box-header {
        border-bottom: 1px solid #f3f5f5;
        padding: 0 10px;
    }

        .box-header > .box-title {
            height: 62px;
            line-height: 62px;
        }

    .top-center a[href] {
        color: #32a9ff;
    }

    a {
        color: #32a9ff;
    }

    .pager li > a {
        border: none;
    }

    .main-right-search {
        padding: 20px;
        border-radius: 4px;
        -moz-border-radius: 4px;
        background-color: #fbfbfb;
        padding-top: 0;
    }

    .form-search li {
        float: left;
        overflow: hidden;
        margin-right: 17px;
        width: 350px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        border: solid 1px #E0E5E5;
        border-radius: 4px;
        -moz-border-radius: 4px;
        list-style: none;
        margin-top: 8px;
        margin-bottom: 0;
        padding: 0;
    }

        .form-search li label {
            float: left;
            width: 110px;
            color: #666;
            text-align: center;
            border-right: solid 1px #E0E5E5;
        }

        .form-search li input {
            float: left;
            border: none;
            background: none;
            outline: none;
            text-indent: 6px;
            -webkit-appearance: none;
            background-color: #ffffff;
            width: 235px;
            height: 26px;
            color: #333333;
            line-height: 28px;
        }

    .form-search-margin {
        margin-top: 12px;
    }

    .form-search li .inline-text {
        padding-left: 5px;
    }

    .pager {
        padding-left: 0;
        margin: 20px 0;
        text-align: center;
        list-style: none;
    }

        .pager li {
            display: inline;
        }

        .pager ul li {
            list-style: none outside none;
        }

        .pager li > a {
            border: none;
        }

        .pager li > a, .pager li > span {
            display: inline-block;
            padding: 5px 14px;
            background-color: #fff;
            /*border: 1px solid #ddd;*/
            border-radius: 15px;
        }

    .table {
        width: 100%;
        margin-bottom: 20px;
    }
</style>
